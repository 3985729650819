/* ServicePage.module.css */
.servicesPage {
    padding: 20px;
    background-color: #f9f9fa; /* Slightly off-white background for subtle contrast */
  }
  
  .introSection {
    text-align: center;
    padding: 40px 20px; /* Ample padding for focus on content */
    background-color: #ffffff; /* White background to highlight this section */
    border-radius: 8px; /* Soft rounded corners for a modern feel */
    margin-bottom: 2rem; /* Space before services section */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .introSection h1 {
    color: #004080; /* Consistent theme blue */
    margin-bottom: 1rem; /* Space between heading and paragraph */
  }
  
  .introSection p {
    color: #666; /* Dark grey for readable text */
    max-width: 800px; /* Ensures text line length is comfortable for reading */
    margin: auto; /* Centers the text block within the section */
  }
  
  .ctaSection {
    text-align: center;
    padding: 40px 20px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-top: 2rem; /* Space after services section */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .ctaSection p {
    color: #666;
    max-width: 800px;
    margin: auto;
    padding-bottom: 1rem; /* Space between paragraphs */
  }

  .ctaButton {
    background-color: #004080;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .ctaButton:hover {
    background-color: #002d5a; /* A darker shade for hover state */
  }
  
  /* Adjustments for the services rendered by ServicesSection might be needed,
     ensure ServicesSection.module.css complements these styles */
  