/* Pricing.module.css */

.pricingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    background-color: #f9f9fa;
  }
  
  .pricingHeader {
    text-align: center;
    max-width: 800px;
    margin-bottom: 40px;
  }
  
  .pricingHeader h2 {
    color: #004080;
  }
  
  .pricingHeader p {
    color: #666;
    margin: auto;
    max-width: 600px;
  }
  
  .tiers {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  
  .tier {
    background-color: #ffffff;
    border: 1px solid #e0e0e0; /* Adds a subtle border for definition */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-width: 250px;
    max-width: 300px;
    text-align: center;
    transition: box-shadow 0.3s ease;
  }
  
  .tier:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover for a modern effect */
  }
  
  .header {
    font-size: 1.2rem;
    color: white;
    background-color: #007bff; /* Consider using individual colors for each tier */
    text-align: center;
    padding: 10px 0;
    border-radius: 8px 8px 0 0;
    width: 100%;
    display: block;
  }
  
  .price {
    font-size: 2rem;
    color: #333;
    margin: 20px 0;
  }
  
  .features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .features li {
    margin-bottom: 10px;
    color: #333;
    font-size: 0.9rem;
  }
  
  /* Removing the background color for a cleaner look */
  .features li:not(:last-child) {
    border-bottom: 1px solid #e0e0e0; /* Adds a light divider for a modern, minimalistic touch */
    padding-bottom: 10px; /* Space above the divider */
  }
  
  .features li:last-child {
    padding-bottom: 0; /* No extra padding for the last item */
  }
  