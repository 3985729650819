/* General Page Styles */
.page {
  font-family: 'Segoe UI', Arial, sans-serif;
  color: #333;
  background-color: #fff;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

/* Header Section */
.header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
}

.header h1 {
  color: #004080;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.header p {
  color: #007bff;
  font-size: 1.2rem;
}

/* Mission Statement Section */
.missionSection {
  background-color: #f4f4f4;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
}

/* Team and Services Sections */
.flexContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.teamSection,
.servicesSection {
  flex: 1;
  padding: 20px;
  background-color: #e6f0fa;
  border-radius: 8px;
}

.teamSection img,
.servicesSection img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* History and Values Section */
.historyValuesSection {
  background-color: #f9f9fa;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
}

/* Call to Action Footer */
.ctaFooter {
  background-color: #e1f5fe;
  text-align: center;
  padding: 30px;
  margin-top: 30px;
  border-radius: 8px;
  color: #0056b3;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .flexContainer {
    flex-direction: column;
  }
}
