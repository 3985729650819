/* Header.module.css adjustments for navbar toggle and logo link */

.header {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: 0; /* Ensure no margin is causing a gap */
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  margin: 0; /* Reset any margin */
}

.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo img {
  height: 50px; /* Adjusted for consistency */
}

.navigation {
  list-style: none;
  display: flex;
  gap: 20px;
}

/* Adjustments for mobile */
.navToggle {
  display: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.navToggle:focus {
  outline: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
  background-color: #333;
}

@media (max-width: 768px) {
  .navigation {
    display: none;
    position: absolute;
    top: 100%; /* Directly below the header */
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #fff;
    padding-top: 0; /* Remove padding that might cause a gap */
    padding-bottom: 1rem; /* Adjust padding as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 0; /* Ensure no margin at the top */
  }

  .navigation.show {
    display: flex;
  }

  .navToggle {
    display: block;
  }

  .navigation li a {
    padding: 10px 20px; /* Ensure adequate touch targets */
  }
}
