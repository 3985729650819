/* HeroSection.module.css */
.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 50vh; /* Adjust based on your preference */
  color: #fff;
  background-color: #000; /* Fallback color */
}

.heroImageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: -1; Ensures the image stays in the background */
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Covers the hero section nicely */
  object-position: center; /* Adjusts the position of the image */
}

.heroContent {
  z-index: 1;
  text-align: center;
  padding: 20px; /* Adds some padding around the text */
}

h1 {
  margin-bottom: 0.5em;
  font-size: 2.5rem; /* Large, readable text */
}

p {
  margin-bottom: 1em;
  font-size: 1.2rem; /* Clear, concise description */
}

.cta {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  text-decoration: none; /* Assuming it's a link */
  display: inline-block; /* Allows for padding and margin */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero {
    height: 60vh;
  }

  .heroContent h1 {
    font-size: 2rem; /* Slightly smaller for smaller devices */
  }

  .heroContent p {
    font-size: 1rem;
  }
}
