/* HomePageContent.module.css */

.homeContent {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
    padding: 4rem 0;
    background-color: #f9f9fa; /* Light background to keep it clean and professional */
  }
  
  .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 200px; /* Adjust width as needed */
  }
  
  .iconContainer img {
    width: 80px; /* Adjust based on the actual size of your icons */
    height: auto;
    margin-bottom: 1rem; /* Space between the icon and the label */
  }
  
  .iconContainer p {
    font-size: 1rem; /* Adjust font size as needed */
    color: #333; /* Text color */
    margin: 0 0 1rem 0; /* Adjust spacing around the text */
    line-height: 1.4; /* Line height for better readability */
  }
  
  .descriptionText {
    font-size: 0.85rem; /* Smaller font size for the description text */
    color: #666; /* Slightly lighter color for the descriptions */
    max-width: 180px; /* Control the width of the text to maintain layout */
    margin-top: 0.5rem; /* Space between the title and the description */
  }
  
  a {
    color: #333; /* Link color */
    text-decoration: none; /* No underline */
  }
  
  a:hover {
    color: #007bff; /* Highlight color on hover */
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .homeContent {
      flex-direction: column;
      gap: 30px;
    }
  
    .iconContainer {
      width: 100%; /* Full width on smaller screens */
    }
  }
  