/* ServicesSection.module.css */
.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Ensures flex items are centered */
  gap: 20px;
  padding: 20px;
  margin: auto; /* Center the container */
}


.serviceCard {
  background-color: #ffffff; /* Matches the intro and CTA sections */
  border-radius: 8px;
  width: 20%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px; /* Consistent internal spacing */
  text-align: center; /* Center-align content */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.serviceCard:hover {
  transform: translateY(-5px); /* Slightly raise the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Enhance shadow for depth */
}

.serviceCard img {
  width: 60px; /* Adjust based on actual icon size */
  height: auto;
  margin-bottom: 20px; /* Space between icon and title */
}

.serviceCard h3 {
  color: #004080; /* Theme blue for titles */
  margin-bottom: 10px;
}

.serviceCard p {
  color: #666; /* Grey for description, ensuring readability */
}

@media (max-width: 768px) {
  .serviceCard {
    flex: 0 1 calc(100% - 40px); /* Full width on smaller screens, accounting for padding */
  }
}