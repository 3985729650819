/* General Container */
.contactContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Intro Section */
.contactIntro, .contactDetails, .formSection, .stayConnected {
  background-color: #f9f9fa; /* Light grey background */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
}

.contactIntro h1, .formSection h2 {
  color: #004080; /* Deep blue for headers */
  margin-bottom: 15px;
}

.contactIntro p, .contactDetails p, .stayConnected p {
  color: #333; /* Dark grey for text */
  line-height: 1.6;
  margin-bottom: 10px;
}

/* Contact Details */
.contactDetails strong {
  color: #0056b3; /* Slightly lighter blue for emphasis */
}

/* Form Section */
.formSection {
  background-color: #ffffff; /* White background for form section */
}

.formField {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  color: #0056b3; /* Label color */
}

.input, .textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc; /* Light grey border */
  resize: vertical; /* Allows vertical resize for textarea */
}

.submitButton {
  background-color: #004080; /* Button background color */
  color: #ffffff; /* Button text color */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #002d5a; /* Darker blue on hover */
}

/* Stay Connected Section */
.stayConnected {
  text-align: center; /* Centering the content */
}

/* Social media icons or links styles here */
