/* Footer.css */
footer {
  background-color: #424242; /* A deep gray for a strong, professional foundation */
  color: #ffffff; /* White text for contrast and readability */
  padding: 2rem 0;
  text-align: center;
}

footer .contact-info, footer .quick-links, footer .legal {
  margin-bottom: 1rem;
}

footer a {
  color: #ffffff; /* Maintains white text for links to ensure they are readable */
  text-decoration: none;
}

footer a:hover {
  color: #9e9e9e; /* A lighter gray for hover, providing a subtle yet noticeable interactive cue */
}
