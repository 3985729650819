/* Resources.module.css */
.resourcesPage {
  padding: 40px;
  background-color: #ffffff; /* Brighter background for better contrast */
}

.introSection, .resourcesList, .toolsSection, .ctaSection {
  background-color: #f9f9fa; /* Soft background for each section */
  border-radius: 12px; /* Rounded edges for a modern look */
  box-shadow: 0 4px 8px rgba(0,0,0,0.05); /* Subtle shadow for depth */
  padding: 30px;
  margin-bottom: 30px; /* Increased spacing between sections */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.introSection h1, .resourcesList h2, .toolsSection h2 {
  color: #004080; /* Deep blue for headings */
  margin-bottom: 20px;
}

.introSection p, .resourcesList li, .toolsSection p, .ctaSection p {
  color: #333; /* Darker text for enhanced readability */
  line-height: 1.6; /* Improved line spacing */
  margin-bottom: 15px; /* Consistent spacing between paragraphs and list items */
}

.resourcesList ul {
  list-style: none; /* Removes default list styling */
  padding: 0;
}

.resourcesList li {
  margin-bottom: 15px; /* Spacing between list items */
  padding-left: 20px; /* Indent text for better structure */
  position: relative; /* Prepare for pseudo-element (bullet) positioning */
}

.resourcesList li:before {
  content: '•'; /* Custom bullet */
  color: #007bff; /* Bullet color */
  position: absolute;
  left: 0;
}

.resourcesList a {
  color: #007bff; /* Link color */
  font-weight: 500; /* Slightly bolder links */
  transition: color 0.2s ease-in-out; /* Smooth color transition for hover */
}

.resourcesList a:hover {
  color: #0056b3; /* Darker shade on hover */
  text-decoration: underline; /* Underline for clear interactivity */
}

.ctaSection {
  text-align: center; /* Center-align CTA text */
  padding: 40px 20px; /* Generous padding for emphasis */
  background-color: #e8f0fe; /* Lighter background for CTA section */
  border-radius: 12px; /* Consistent rounded edges */
  box-shadow: 0 4px 8px rgba(0,0,0,0.05); /* Consistent shadow */
}

.ctaSection p {
  max-width: 800px; /* Optimal reading width */
  margin: 20px auto; /* Center content and provide spacing */
}

/* Interactive Enhancements */
.introSection:hover, .resourcesList:hover, .toolsSection:hover, .ctaSection:hover {
  box-shadow: 0 8px 16px rgba(0,0,0,0.1); /* Deeper shadow on hover for a "lifted" effect */
}


.toolsSection a {
  text-decoration: none; /* Removes underline from links */
  color: #0056b3; /* Deep blue for links */
  transition: color 0.2s ease-in-out; /* Smooth transition for hover effect */
}

.toolsSection a:hover {
  color: #003d7a; /* Darker blue on hover for better visibility */
  text-decoration: underline; /* Adds underline on hover for emphasis */
}

/* Additional list styling if necessary */
.toolsSection ul {
  list-style-type: none; /* Removes default list bullets */
  padding-left: 0; /* Adjusts padding for alignment */
}

.toolsSection li {
  margin-bottom: 10px; /* Space between items */
}

.resourceLogo {
  width: 40px; /* Control the size of the logos */
  height: auto; /* Maintain aspect ratio */
  margin-right: 15px; /* Space between logo and text */
  vertical-align: middle; /* Align the logo with the text vertically */
}